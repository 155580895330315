.chat-visual-window {
  width: 370px;
  background: #353535;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-visual-header {
  background: #4171ca;
  min-height: 75px;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
}

.chat-visual-header-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-visual-header-title {
  flex: 1;
  font-size: 18px;
  padding: 10px;
  text-align: start;
  user-select: none;
  border-radius: 5px;
  align-self: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.chat-visual-header-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-visual-header-button:hover {
  background: #4882ed;
}

.chat-visual-messages {
  padding: 20px;
  height: 456px;
  overflow-y: auto;
}

.chat-visual-message {
  margin: 10px 0;
  display: flex;
}

.chat-visual-message.them {
  justify-content: flex-start;
  margin-left: 15px;
}

.chat-visual-message.me {
  justify-content: flex-end;
  margin-right: 15px;
}

.chat-visual-bubble {
  max-width: 210px;
  padding: 10px 15px;
  border-radius: 6px;
  text-align: start;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.chat-visual-bubble.them {
  background: #242424;
  color: #ffffff;
}

.chat-visual-bubble.me {
  background: #242424;
  color: #ffffff;
}

.chat-visual-timestamp {
  font-size: 16px;
  color: #b5b5b5;
  margin-top: 5px;
  text-align: right;
}

.chat-visual-input {
  height: 58px;
  margin: 0;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #424242;
  color: #696969;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.chat-visual-textarea {
  width: 300px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #424242;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-visual-textarea::placeholder {
  color: #696969;
}

.chat-visual-input-buttons {
  width: 100px;
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.chat-visual-send {
  width: 30px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat-visual-send svg {
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #0645ad;
  transition: color 0.2s ease;
}

.chat-visual-send:hover svg {
  color: rgba(86, 88, 103, 1);
}

.chat-visual-input.active {
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}
